export const NavigationSubscriptionIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 17C5.46957 17 4.96086 16.7893 4.58579 16.4142C4.21071 16.0391 4 15.5304 4 15V6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H15C15.5304 4 16.0391 4.21071 16.4142 4.58579C16.7893 4.96086 17 5.46957 17 6V15C17 15.5304 16.7893 16.0391 16.4142 16.4142C16.0391 16.7893 15.5304 17 15 17H6ZM9.25 5.5H11.75V7.5H9.25V5.5ZM7.75 5.5H6C5.86739 5.5 5.74021 5.55268 5.64645 5.64645C5.55268 5.74021 5.5 5.86739 5.5 6V7.5H7.75V5.5ZM7.75 9H5.5V15C5.5 15.1326 5.55268 15.2598 5.64645 15.3536C5.74021 15.4473 5.86739 15.5 6 15.5H7.75V9ZM9.25 15.5V9H11.75V15.5H9.25ZM13.25 7.5V5.5H15C15.1326 5.5 15.2598 5.55268 15.3536 5.64645C15.4473 5.74021 15.5 5.86739 15.5 6V7.5H13.25ZM13.25 9V15.5H15C15.1326 15.5 15.2598 15.4473 15.3536 15.3536C15.4473 15.2598 15.5 15.1326 15.5 15V9H13.25Z"
        fill="#5C5F62"
      />
    </svg>
  );
};
