import { Modal } from "@shopify/polaris";
import { noop } from "lodash";
import React, { ReactNode } from "react";

interface VendorPortalModalProps {
  title: string;
  onClose?: () => void;
  className?: string;
  children: ReactNode;
}

export function VendorPortalModal({ onClose, title, children }: VendorPortalModalProps): JSX.Element | null {
  return (
    <Modal open={true} onClose={onClose || noop} title={title}>
      <Modal.Section>
        <div className="vendor-portal-modal-content-wrapper">{children}</div>
      </Modal.Section>
    </Modal>
  );
}
