import { IS_BROWSER, fromShopifyAppPropertyName, appPrefixedHost } from "@smartrr/shared/constants";
import { IVendorUser } from "@smartrr/shared/entities/VendorUser";
import { getParsedQuery, getParsedQueryPrimitives } from "@smartrr/shared/utils/frontendQueryUtils";
import { getShopifyIframeAppStartingUrl } from "@smartrr/shared/utils/getShopifyAppUrl";
import { Primitive } from "@smartrr/shared/utils/PrimitiveKeys";
import config from "@vendor-app/config";

import { getShopUrlFromGlobals } from "./getShopUrlFromGlobals";
import { redirectToShopifyAuth } from "./redirectToShopifyAuth";
import { isInShopifyIframe } from "../isInIframe";
import { vendorPortalFrontendRedirect } from "../vendorPortalFrontendRedirect";
import { useEffect, useState } from "react";

/**
 * used in admin auth container. returns true if redirecting
 */
export function useRequiredAuthRedirects(userIsLoading: boolean, user: IVendorUser | null): boolean {
  const [isRedirecting, setIsRedirecting] = useState(false);
  const shopUrl = getShopUrlFromGlobals();

  useEffect(() => {
    if (!userIsLoading && !user) {
      // redirect to shopify app for authentication if unauthenticated
      void doAuthenticationRedirect(shopUrl);
      setIsRedirecting(true);
    }
  }, [userIsLoading, user]);

  useEffect(() => {
    const shopifyRedirectToAppNeeded = shopifyRedirectToAppNeededCheck();

    if (user && shopifyRedirectToAppNeeded && shopUrl) {
      void doShopifyAppRedirect(getParsedQueryPrimitives(), shopUrl);
      setIsRedirecting(true);
    }
  }, [user, shopUrl]);

  return isRedirecting;
}

/**
 * redirects to either the current shop's shopify auth or the Smartrr login
 * screen (if no current shop)
 */
async function doAuthenticationRedirect(shopUrl?: string): Promise<void> {
  if (!IS_BROWSER) {
    return;
  }

  if (shopUrl) {
    return redirectToShopifyAuth(shopUrl);
  }

  return vendorPortalFrontendRedirect(`https://${appPrefixedHost}/login`);
}

/**
 * redirects to shopify app (the app view inside iframe, not auth)
 */
async function doShopifyAppRedirect(
  queryObj: { [key: string]: Primitive } = getParsedQueryPrimitives(),
  shopUrl: string
): Promise<void> {
  if (IS_BROWSER) {
    return vendorPortalFrontendRedirect(getShopifyIframeAppStartingUrl(queryObj, shopUrl, config.shopify.appId));
  }
}

function shopifyRedirectToAppNeededCheck(): boolean {
  return Boolean(getParsedQuery()[fromShopifyAppPropertyName]) && !isInShopifyIframe && !!getShopUrlFromGlobals();
}
