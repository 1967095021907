import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import {
  SHOPIFY_HEADERS,
  apiPrefixedHost,
  jwtPropertyName,
  vendorApiRoutePrefix,
} from "@smartrr/shared/constants";
import { createApolloShopifyExtensionLink } from "@smartrr/shared/shopifyGraphQL";
import { getVendorBearerToken, makeBearerTokenHeaderObj } from "@smartrr/shared/utils/authToken";
import { ReactNode, useMemo } from "react";

import { useMyShopifyDomainSelector } from "@vendor-app/app/_state/reducers/shopify";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

interface Props {
  children?: ReactNode;
}

export function ApolloProviderWrapper(props: Props) {
  const myShopifyDomain = useMyShopifyDomainSelector();
  const authToken = useSmartrrVendorSelector(state => state.auth[jwtPropertyName]) || getVendorBearerToken();
  const apolloClient = useMemo(
    () => (myShopifyDomain && authToken ? createBrowserApolloClient(myShopifyDomain, authToken) : null),
    [myShopifyDomain, authToken]
  );

  return apolloClient ? <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider> : null;
}

function createBrowserApolloClient(myShopifyDomain: string, authToken: string) {
  const uri = `https://${apiPrefixedHost}${vendorApiRoutePrefix}/integrations/shopify/graphql`;
  return new ApolloClient({
    cache: new InMemoryCache(),
    uri,
    link: createApolloShopifyExtensionLink(uri, {
      [SHOPIFY_HEADERS.SHOP_DOMAIN]: myShopifyDomain,
      ...makeBearerTokenHeaderObj(authToken),
    }),
  });
}
