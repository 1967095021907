import React, { useEffect, useState } from "react";

import { AvailableDomainsSelection } from "../AvailableShopifyOrgsSelection";
import { VendorPortalModal } from "../VendorPortalModal";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { HorizontalStack, Spinner } from "@shopify/polaris";
import { loadAllOrgs } from "@vendor-app/app/_state/actionCreators/organization";

interface SelectOrganizationModalProps {
  onClose?: () => void;
}

export function SelectOrganizationModal({ onClose }: SelectOrganizationModalProps): JSX.Element | null {
  const vendorOrganizations = useSmartrrVendorSelector(state => state.vendorOrganizations.organizations);
  const dispatch = useSmartrrVendorDispatch();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadOrgs = async () => {
      setIsLoading(true);
      await loadAllOrgs(dispatch);
      setIsLoading(false);
    };

    if (vendorOrganizations.length <= 1) {
      loadOrgs();
    }
  }, [vendorOrganizations]);

  return (
    <VendorPortalModal title="Select another shop" onClose={onClose}>
      {isLoading ? (
        <HorizontalStack align="center">
          <Spinner />
        </HorizontalStack>
      ) : (
        <AvailableDomainsSelection onSuccess={onClose}>
          <div>
            Your Smartrr <i>.myshopify</i> domains:
          </div>
        </AvailableDomainsSelection>
      )}
    </VendorPortalModal>
  );
}
