export const NavigationReportIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_22002_123876)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.95216 3.5H10.0482C10.2302 3.5 10.4192 3.5 10.5912 3.534C10.9306 3.60152 11.2425 3.7682 11.4872 4.01295C11.732 4.25769 11.8986 4.56953 11.9662 4.909C12.0012 5.081 12.0002 5.27 12.0002 5.452V14.548C12.0002 14.73 12.0002 14.919 11.9662 15.091C11.8988 15.4307 11.7322 15.7427 11.4875 15.9877C11.2427 16.2326 10.9308 16.3994 10.5912 16.467C10.4116 16.4954 10.2298 16.5065 10.0482 16.5H9.95216C9.77016 16.5 9.58116 16.5 9.40916 16.466C9.06968 16.3985 8.75785 16.2318 8.5131 15.9871C8.26835 15.7423 8.10168 15.4305 8.03416 15.091C8.00538 14.9115 7.994 14.7297 8.00016 14.548V5.452C8.00016 5.27 8.00016 5.081 8.03416 4.909C8.10168 4.56953 8.26835 4.25769 8.5131 4.01295C8.75785 3.7682 9.06968 3.60152 9.40916 3.534C9.58116 3.499 9.77016 3.5 9.95216 3.5ZM9.69916 5.005C9.65143 5.01491 9.60764 5.03855 9.57317 5.07301C9.5387 5.10748 9.51506 5.15127 9.50516 5.199C9.50167 5.29896 9.5 5.39898 9.50016 5.499V14.5C9.5 14.6 9.50167 14.7 9.50516 14.8C9.51489 14.8479 9.53845 14.8919 9.57293 14.9266C9.60741 14.9612 9.6513 14.985 9.69916 14.995L9.75216 14.998C9.80716 15 9.87916 15 10.0002 15C10.1212 15 10.1932 15 10.2482 14.998L10.3012 14.995C10.3489 14.9851 10.3927 14.9615 10.4271 14.927C10.4616 14.8925 10.4853 14.8487 10.4952 14.801L10.4982 14.748C10.5001 14.6654 10.5008 14.5827 10.5002 14.5V5.5C10.5002 5.379 10.5002 5.307 10.4982 5.252L10.4952 5.199C10.4853 5.15127 10.4616 5.10748 10.4271 5.07301C10.3927 5.03855 10.3489 5.01491 10.3012 5.005C10.2012 5.00151 10.1012 4.99984 10.0012 5C9.90047 4.99982 9.79979 5.00149 9.69916 5.005Z"
          fill="#5C5F62"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.45216 9H5.54816C5.73016 9 5.91916 9 6.09116 9.034C6.43064 9.10152 6.74247 9.2682 6.98722 9.51295C7.23197 9.7577 7.39864 10.0695 7.46616 10.409C7.50116 10.581 7.50016 10.77 7.50016 10.952V14.548C7.50016 14.73 7.50016 14.919 7.46616 15.091C7.39882 15.4307 7.23222 15.7427 6.98746 15.9877C6.7427 16.2326 6.43077 16.3994 6.09116 16.467C5.91164 16.4954 5.7298 16.5065 5.54816 16.5H5.45216C5.27016 16.5 5.08116 16.5 4.90916 16.466C4.56968 16.3985 4.25785 16.2318 4.0131 15.9871C3.76835 15.7423 3.60168 15.4305 3.53416 15.091C3.50538 14.9115 3.494 14.7297 3.50016 14.548V10.952C3.50016 10.77 3.50016 10.581 3.53416 10.409C3.60168 10.0695 3.76835 9.7577 4.0131 9.51295C4.25785 9.2682 4.56968 9.10152 4.90916 9.034C5.08116 8.999 5.27016 9 5.45216 9ZM5.19916 10.505C5.15143 10.5149 5.10764 10.5385 5.07317 10.573C5.0387 10.6075 5.01506 10.6513 5.00516 10.699C5.00167 10.799 5 10.899 5.00016 10.999V14.5C5 14.6 5.00167 14.7 5.00516 14.8C5.01489 14.8479 5.03845 14.8919 5.07293 14.9266C5.10741 14.9612 5.1513 14.985 5.19916 14.995L5.25216 14.998C5.30716 15 5.37916 15 5.50016 15C5.62116 15 5.69316 15 5.74816 14.998L5.80116 14.995C5.84889 14.9851 5.89268 14.9615 5.92715 14.927C5.96162 14.8925 5.98526 14.8487 5.99516 14.801L5.99816 14.748C6.00016 14.694 6.00016 14.621 6.00016 14.5V11C6.00032 10.9 5.99865 10.8 5.99516 10.7C5.98543 10.6521 5.96187 10.6081 5.92739 10.5734C5.89291 10.5388 5.84902 10.515 5.80116 10.505C5.7012 10.5015 5.60118 10.4998 5.50116 10.5C5.40047 10.4998 5.29979 10.5014 5.19916 10.505Z"
          fill="#5C5F62"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.5002 6H14.4522C14.2702 6 14.0812 6 13.9092 6.034C13.5697 6.10152 13.2579 6.2682 13.0131 6.51295C12.7684 6.75769 12.6017 7.06953 12.5342 7.409C12.5054 7.58849 12.494 7.77033 12.5002 7.952V14.548C12.5002 14.73 12.5002 14.919 12.5342 15.091C12.6015 15.4307 12.7681 15.7427 13.0129 15.9877C13.2576 16.2326 13.5695 16.3994 13.9092 16.467C14.0812 16.501 14.2702 16.5 14.4522 16.5H14.5482C14.7302 16.5 14.9192 16.5 15.0912 16.466C15.4306 16.3985 15.7425 16.2318 15.9872 15.9871C16.232 15.7423 16.3986 15.4305 16.4662 15.091C16.5012 14.919 16.5002 14.731 16.5002 14.548V7.952C16.5002 7.77 16.5002 7.581 16.4662 7.409C16.3986 7.06953 16.232 6.75769 15.9872 6.51295C15.7425 6.2682 15.4306 6.10152 15.0912 6.034C14.9192 5.999 14.7302 6 14.5482 6H14.5002ZM14.0052 7.7C14.0149 7.65209 14.0384 7.60808 14.0729 7.57342C14.1074 7.53876 14.1513 7.51498 14.1992 7.505C14.2991 7.50151 14.3991 7.49984 14.4992 7.5C14.5998 7.49982 14.7005 7.50149 14.8012 7.505C14.8489 7.51491 14.8927 7.53855 14.9271 7.57301C14.9616 7.60748 14.9853 7.65127 14.9952 7.699L14.9982 7.752C15.0002 7.807 15.0002 7.879 15.0002 8V14.5C15.0002 14.621 15.0002 14.694 14.9982 14.748L14.9952 14.801C14.9853 14.8487 14.9616 14.8925 14.9271 14.927C14.8927 14.9615 14.8489 14.9851 14.8012 14.995L14.7482 14.998C14.6655 14.9999 14.5828 15.0006 14.5002 15C14.3792 15 14.3072 15 14.2522 14.998L14.1992 14.995C14.1514 14.9851 14.1076 14.9615 14.0732 14.927C14.0387 14.8925 14.0151 14.8487 14.0052 14.801L14.0022 14.748C14.0002 14.6654 13.9996 14.5827 14.0002 14.5V8C14.0002 7.879 14.0002 7.807 14.0022 7.752L14.0052 7.7Z"
          fill="#5C5F62"
        />
      </g>
      <defs>
        <clipPath id="clip0_22002_123876">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
