import { IOrganization } from "@smartrr/shared/entities/Organization";
import { IVendorUser } from "@smartrr/shared/entities/VendorUser";
import React, { ReactNode, useState } from "react";

import { useActiveOrganizationIdSelector } from "@vendor-app/app/_state/reducers/organizations";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { ButtonWithAnyChildren } from "@vendor-app/app/AdminRoute/components/elements/ButtonWithAnyChildren";
import { redirectToShopifyAuth } from "@vendor-app/utils/shopify/redirectToShopifyAuth";

interface IAvailableDomainsSelection {
  onSuccess?: (orgId: string) => void;
  children: ReactNode;
}

export function AvailableDomainsSelection({
  onSuccess,
  children,
}: IAvailableDomainsSelection): JSX.Element | null {
  const vendorOrganizations = useSmartrrVendorSelector(state => state.vendorOrganizations.organizations);
  const activeOrganizationId = useActiveOrganizationIdSelector();
  const vendorUser = useSmartrrVendorSelector(state => state.auth.user);

  if (!vendorOrganizations.length || vendorOrganizations.length === 1 || !vendorUser) {
    return null;
  }

  return (
    <div className="column">
      {children}
      {vendorOrganizations
        .filter(vendorOrg => vendorOrg.id !== activeOrganizationId)
        .map(vendorOrg => (
          <SelectOrganizationButton
            key={vendorOrg.id}
            onSuccess={onSuccess}
            user={vendorUser}
            organization={vendorOrg}
          />
        ))}
    </div>
  );
}

interface ISelectOrganizationButtonProps {
  onSuccess?: (orgId: string) => void;
  user: IVendorUser;
  organization: IOrganization;
}

const errorMessageStyle = {
  color: "red",
};

const buttonContainerStyle = {
  marginTop: 8,
  marginBottom: 8,
};

function SelectOrganizationButton({ onSuccess, organization }: ISelectOrganizationButtonProps): JSX.Element {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  return (
    <div style={buttonContainerStyle}>
      <ButtonWithAnyChildren
        primary
        onClick={async () => {
          if (organization.myShopifyDomain) {
            void redirectToShopifyAuth(organization.myShopifyDomain, true);
            if (onSuccess) {
              onSuccess(organization.id);
            }
          } else {
            setErrorMessage(
              `Error: no myShopifyDomain associated with organization ID: ${organization.id}, use Add .myshopify Domain flow below`
            );
          }
        }}
      >
        {organization.orgName}
      </ButtonWithAnyChildren>
      {!!errorMessage && <div style={errorMessageStyle}>{errorMessage}</div>}
    </div>
  );
}
