import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { useCurrentOrganization } from "@vendor-app/utils/useCurrentOrganization";
import { useUser } from "@vendor-app/utils/useUser";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useMemo } from "react";

export const useLDIdentityVendor = async () => {
  const ldClient = useLDClient();
  const vendorUser = useUser();
  const org = useCurrentOrganization();
  const activePlan = useSmartrrVendorSelector(state => state.accountPlans.activePlan);

  await useMemo(async () => {
    if (!vendorUser || !ldClient) {
      return;
    }

    const {
      id: vendorUserId,
      originalEmail: email,
      originalFirstName: firstName,
      originalLastName: lastName,
      isSuperUser,
    } = vendorUser;
    const { orgName, id: organizationId, myShopifyDomain, setup } = org;
    await ldClient.identify({
      kind: "multi",
      vendorUser: {
        key: vendorUserId,
        name: `${firstName} ${lastName}`,
        email,
        isSuperUser,
      },
      organization: {
        key: organizationId,
        orgName,
        myShopifyDomain,
        setup,
        planName: activePlan?.planName ?? "none",
      },
    });
  }, [vendorUser, ldClient, org]);
};
