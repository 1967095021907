export const NavigationNotificationsIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_22002_123897)">
        <path
          d="M6.56677 7.56977C6.47747 7.52814 6.38085 7.50451 6.28242 7.50022C6.18398 7.49594 6.08567 7.51108 5.99309 7.54479C5.90051 7.57849 5.81548 7.63011 5.74284 7.69668C5.67021 7.76325 5.6114 7.84347 5.56977 7.93277C5.52814 8.02207 5.50451 8.11869 5.50022 8.21713C5.49594 8.31556 5.51108 8.41387 5.54479 8.50645C5.61286 8.69342 5.75242 8.8457 5.93277 8.92977L9.47077 10.5808C9.63639 10.6581 9.81697 10.6982 9.99977 10.6982C10.1826 10.6982 10.3631 10.6581 10.5288 10.5808L14.0668 8.92977C14.2471 8.8457 14.3867 8.69342 14.4548 8.50645C14.5228 8.31948 14.5138 8.11312 14.4298 7.93277C14.3457 7.75242 14.1934 7.61286 14.0065 7.54479C13.8195 7.47671 13.6131 7.4857 13.4328 7.56977L9.99977 9.17277L6.56677 7.56977Z"
          fill="#5C5F62"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.75 4.5C5.75544 4.5 4.80161 4.89509 4.09835 5.59835C3.39509 6.30161 3 7.25544 3 8.25V11.75C3 12.7446 3.39509 13.6984 4.09835 14.4017C4.80161 15.1049 5.75544 15.5 6.75 15.5H13.25C14.2446 15.5 15.1984 15.1049 15.9017 14.4017C16.6049 13.6984 17 12.7446 17 11.75V8.25C17 7.25544 16.6049 6.30161 15.9017 5.59835C15.1984 4.89509 14.2446 4.5 13.25 4.5H6.75ZM4.5 8.25C4.5 7.65326 4.73705 7.08097 5.15901 6.65901C5.58097 6.23705 6.15326 6 6.75 6H13.25C13.8467 6 14.419 6.23705 14.841 6.65901C15.2629 7.08097 15.5 7.65326 15.5 8.25V11.75C15.5 12.3467 15.2629 12.919 14.841 13.341C14.419 13.7629 13.8467 14 13.25 14H6.75C6.15326 14 5.58097 13.7629 5.15901 13.341C4.73705 12.919 4.5 12.3467 4.5 11.75V8.25Z"
          fill="#5C5F62"
        />
      </g>
      <defs>
        <clipPath id="clip0_22002_123897">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
