export const NavigationSubscriptionProgramIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_22002_123884)">
        <path
          d="M3.5 5.25C3.5 4.284 4.284 3.5 5.25 3.5H6.25C6.44891 3.5 6.63968 3.57902 6.78033 3.71967C6.92098 3.86032 7 4.05109 7 4.25C7 4.44891 6.92098 4.63968 6.78033 4.78033C6.63968 4.92098 6.44891 5 6.25 5H5.25C5.1837 5 5.12011 5.02634 5.07322 5.07322C5.02634 5.12011 5 5.1837 5 5.25V6.25C5 6.44891 4.92098 6.63968 4.78033 6.78033C4.63968 6.92098 4.44891 7 4.25 7C4.05109 7 3.86032 6.92098 3.71967 6.78033C3.57902 6.63968 3.5 6.44891 3.5 6.25V5.25Z"
          fill="#5C5F62"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.5 9.25C3.5 8.284 4.284 7.5 5.25 7.5H14.75C15.716 7.5 16.5 8.284 16.5 9.25V10.75C16.5 11.2141 16.3156 11.6592 15.9874 11.9874C15.6592 12.3156 15.2141 12.5 14.75 12.5H5.25C4.78587 12.5 4.34075 12.3156 4.01256 11.9874C3.68437 11.6592 3.5 11.2141 3.5 10.75V9.25ZM5.25 9C5.1837 9 5.12011 9.02634 5.07322 9.07322C5.02634 9.12011 5 9.1837 5 9.25V10.75C5 10.888 5.112 11 5.25 11H14.75C14.8163 11 14.8799 10.9737 14.9268 10.9268C14.9737 10.8799 15 10.8163 15 10.75V9.25C15 9.1837 14.9737 9.12011 14.9268 9.07322C14.8799 9.02634 14.8163 9 14.75 9H5.25Z"
          fill="#5C5F62"
        />
        <path
          d="M3.5 14.75C3.5 15.716 4.284 16.5 5.25 16.5H6.25C6.44891 16.5 6.63968 16.421 6.78033 16.2803C6.92098 16.1397 7 15.9489 7 15.75C7 15.5511 6.92098 15.3603 6.78033 15.2197C6.63968 15.079 6.44891 15 6.25 15H5.25C5.1837 15 5.12011 14.9737 5.07322 14.9268C5.02634 14.8799 5 14.8163 5 14.75V13.75C5 13.5511 4.92098 13.3603 4.78033 13.2197C4.63968 13.079 4.44891 13 4.25 13C4.05109 13 3.86032 13.079 3.71967 13.2197C3.57902 13.3603 3.5 13.5511 3.5 13.75V14.75Z"
          fill="#5C5F62"
        />
        <path
          d="M14.75 3.5C15.716 3.5 16.5 4.284 16.5 5.25V6.25C16.5 6.44891 16.421 6.63968 16.2803 6.78033C16.1397 6.92098 15.9489 7 15.75 7C15.5511 7 15.3603 6.92098 15.2197 6.78033C15.079 6.63968 15 6.44891 15 6.25V5.25C15 5.1837 14.9737 5.12011 14.9268 5.07322C14.8799 5.02634 14.8163 5 14.75 5H13.75C13.5511 5 13.3603 4.92098 13.2197 4.78033C13.079 4.63968 13 4.44891 13 4.25C13 4.05109 13.079 3.86032 13.2197 3.71967C13.3603 3.57902 13.5511 3.5 13.75 3.5H14.75Z"
          fill="#5C5F62"
        />
        <path
          d="M14.75 16.5C15.2141 16.5 15.6592 16.3156 15.9874 15.9874C16.3156 15.6592 16.5 15.2141 16.5 14.75V13.75C16.5 13.5511 16.421 13.3603 16.2803 13.2197C16.1397 13.079 15.9489 13 15.75 13C15.5511 13 15.3603 13.079 15.2197 13.2197C15.079 13.3603 15 13.5511 15 13.75V14.75C15 14.8163 14.9737 14.8799 14.9268 14.9268C14.8799 14.9737 14.8163 15 14.75 15H13.75C13.5511 15 13.3603 15.079 13.2197 15.2197C13.079 15.3603 13 15.5511 13 15.75C13 15.9489 13.079 16.1397 13.2197 16.2803C13.3603 16.421 13.5511 16.5 13.75 16.5H14.75Z"
          fill="#5C5F62"
        />
        <path
          d="M11.75 4.25C11.75 4.44891 11.671 4.63968 11.5303 4.78033C11.3897 4.92098 11.1989 5 11 5H9C8.80109 5 8.61032 4.92098 8.46967 4.78033C8.32902 4.63968 8.25 4.44891 8.25 4.25C8.25 4.05109 8.32902 3.86032 8.46967 3.71967C8.61032 3.57902 8.80109 3.5 9 3.5H11C11.1989 3.5 11.3897 3.57902 11.5303 3.71967C11.671 3.86032 11.75 4.05109 11.75 4.25Z"
          fill="#5C5F62"
        />
        <path
          d="M11 16.5C11.1989 16.5 11.3897 16.421 11.5303 16.2803C11.671 16.1397 11.75 15.9489 11.75 15.75C11.75 15.5511 11.671 15.3603 11.5303 15.2197C11.3897 15.079 11.1989 15 11 15H9C8.80109 15 8.61032 15.079 8.46967 15.2197C8.32902 15.3603 8.25 15.5511 8.25 15.75C8.25 15.9489 8.32902 16.1397 8.46967 16.2803C8.61032 16.421 8.80109 16.5 9 16.5H11Z"
          fill="#5C5F62"
        />
      </g>
      <defs>
        <clipPath id="clip0_22002_123884">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
