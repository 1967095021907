import { adminConfigRoutePrefix, adminRoutePrefix } from "@smartrr/shared/constants";

function isPath(path: string) {
  if (typeof location === "undefined") {
    return false;
  }

  const currentPathArr = (location?.pathname.split("/").filter(v => v !== "/") || []).filter(Boolean);
  const matchPathArr = (path.split("/").filter(v => v !== "/") || []).filter(Boolean);

  // checks that the beginning of the "current path" matches "path"
  return matchPathArr.every((currentPathChunk, index) => currentPathChunk === currentPathArr[index]);
}

export function isAdmin(): boolean {
  return isPath(adminRoutePrefix);
}

export function isAdminConfig(): boolean {
  return isPath(adminConfigRoutePrefix);
}
