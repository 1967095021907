import { IS_DEV } from "@smartrr/shared/constants";
import { FeatureEnum, IAccountPlan } from "@smartrr/shared/entities/AccountPlan";
import { ISetup } from "@smartrr/shared/entities/Organization";
import { IShopifyData } from "@smartrr/shared/entities/ShopifyData";
import { IVendorUser } from "@smartrr/shared/entities/VendorUser";

interface PendoInformation {
  authUser: IVendorUser;
  activePlan: IAccountPlan | null;
  shopifyData: IShopifyData | undefined;
  setup: ISetup | undefined;
}

interface PendoVisitor {
  id: string;
  email: string | undefined;
}

interface PendoAccount {
  //IShopifyData
  id: string;
  shopifyPlan: string;
  installDate: Date;

  //IAccountPlan
  planLevel: string;
  planStatus: string;
  features: Partial<Record<FeatureEnum, boolean>>;

  //ISetup
  hasProductSelected: boolean;
  isSetupFinished: boolean;
  setupSellingPlanId: string;
  setupThemeName: string;
}

export function initPendo({ authUser, activePlan, shopifyData, setup }: PendoInformation): void {
  const isSuperUser = authUser.isSuperUser;
  const pendo = (window as any).pendo;
  if (!pendo) {
    if (isSuperUser) {
      console.error("Error initializing Pendo");
    }
    return;
  }

  // Visitor
  const visitor: PendoVisitor = {
    id: authUser.id,
    email: authUser.originalEmail,
  };

  // Account
  let account: Partial<PendoAccount> = {};

  if (shopifyData) {
    account = {
      ...account,
      id: shopifyData.name,
      shopifyPlan: shopifyData.plan_name,
      installDate: shopifyData.createdDate,
    };
  }

  if (activePlan) {
    account = {
      ...account,
      planLevel: activePlan.planName,
      planStatus: activePlan.status,
      features: activePlan.features,
    };
  }

  if (setup) {
    account = {
      ...account,
      hasProductSelected: setup.hasProductSelected,
      isSetupFinished: setup.isSetupFinished,
      setupSellingPlanId: setup.selectedSellingPlanId,
      setupThemeName: setup.onSiteSetup?.smartrrSnippets?.themeName ?? "",
    };
  }

  pendo.initialize({ visitor, account });

  if (IS_DEV() && isSuperUser) {
    setTimeout(() => {
      // This will help out to determine if tool is working fine
      if (typeof pendo.validateInstall === "function") {
        pendo.validateInstall();
      }
    }, 5000);
  }
}
