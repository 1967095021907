import "./AdminLayoutContainer.css";
import React from "react";

import { AdminLayoutAndNavigation } from "./AdminLayoutAndNavigation";

interface Props {
  children?: React.ReactNode;
}

export function AdminLayoutContainer({ children }: Props): JSX.Element {
  return <AdminLayoutAndNavigation>{children}</AdminLayoutAndNavigation>;
}
