export const NavigationLoyaltyIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_22002_123915)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.8719 4.12314C9.3249 3.17314 10.6749 3.17314 11.1279 4.12314L12.5179 7.03514L15.7169 7.45614C16.7589 7.59414 17.1769 8.87814 16.4139 9.60214L14.0739 11.8241L14.6609 14.9961C14.8529 16.0301 13.7599 16.8241 12.8359 16.3231L9.9999 14.7831L7.1639 16.3231C6.2399 16.8241 5.1469 16.0301 5.3389 14.9961L5.9259 11.8241L3.5859 9.60214C2.8239 8.87814 3.2409 7.59414 4.2829 7.45614L7.4829 7.03514L8.8719 4.12314ZM9.9999 5.24214L8.7779 7.80314C8.68819 7.99114 8.55293 8.15374 8.3844 8.27617C8.21587 8.39859 8.01942 8.47696 7.8129 8.50414L4.9989 8.87514L7.0569 10.8291C7.3639 11.1211 7.5029 11.5471 7.4259 11.9631L6.9089 14.7541L9.4039 13.4001C9.58687 13.3009 9.79174 13.2489 9.9999 13.2489C10.2081 13.2489 10.4129 13.3009 10.5959 13.4001L13.0909 14.7541L12.5739 11.9641C12.5359 11.7592 12.5497 11.548 12.6142 11.3498C12.6786 11.1515 12.7916 10.9726 12.9429 10.8291L15.0009 8.87514L12.1869 8.50514C11.9803 8.47786 11.7838 8.39934 11.6152 8.27673C11.4467 8.15413 11.3115 7.99133 11.2219 7.80314L9.9999 5.24214Z"
          fill="#5C5F62"
        />
      </g>
      <defs>
        <clipPath id="clip0_22002_123915">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
