export const NavigationIntegrationsIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_22002_123920)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.5 5.75C3.5 5.15326 3.73705 4.58097 4.15901 4.15901C4.58097 3.73705 5.15326 3.5 5.75 3.5H8.5C8.76522 3.5 9.01957 3.60536 9.20711 3.79289C9.39464 3.98043 9.5 4.23478 9.5 4.5V8.5C9.5 8.76522 9.39464 9.01957 9.20711 9.20711C9.01957 9.39464 8.76522 9.5 8.5 9.5H4.5C4.23478 9.5 3.98043 9.39464 3.79289 9.20711C3.60536 9.01957 3.5 8.76522 3.5 8.5V5.75ZM5.75 5C5.55109 5 5.36032 5.07902 5.21967 5.21967C5.07902 5.36032 5 5.55109 5 5.75V8H8V5H5.75Z"
          fill="#5C5F62"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.5 14.25C3.5 14.8467 3.73705 15.419 4.15901 15.841C4.58097 16.2629 5.15326 16.5 5.75 16.5H8.5C8.76522 16.5 9.01957 16.3946 9.20711 16.2071C9.39464 16.0196 9.5 15.7652 9.5 15.5V11.5C9.5 11.2348 9.39464 10.9804 9.20711 10.7929C9.01957 10.6054 8.76522 10.5 8.5 10.5H4.5C4.23478 10.5 3.98043 10.6054 3.79289 10.7929C3.60536 10.9804 3.5 11.2348 3.5 11.5V14.25ZM5.75 15C5.55109 15 5.36032 14.921 5.21967 14.7803C5.07902 14.6397 5 14.4489 5 14.25V12H8V15H5.75Z"
          fill="#5C5F62"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.25 16.5C14.8467 16.5 15.419 16.2629 15.841 15.841C16.2629 15.419 16.5 14.8467 16.5 14.25V11.5C16.5 11.2348 16.3946 10.9804 16.2071 10.7929C16.0196 10.6054 15.7652 10.5 15.5 10.5H11.5C11.2348 10.5 10.9804 10.6054 10.7929 10.7929C10.6054 10.9804 10.5 11.2348 10.5 11.5V15.5C10.5 15.7652 10.6054 16.0196 10.7929 16.2071C10.9804 16.3946 11.2348 16.5 11.5 16.5H14.25ZM15 14.25C15 14.4489 14.921 14.6397 14.7803 14.7803C14.6397 14.921 14.4489 15 14.25 15H12V12H15V14.25Z"
          fill="#5C5F62"
        />
        <path
          d="M13.5 3.5C13.6989 3.5 13.8897 3.57902 14.0303 3.71967C14.171 3.86032 14.25 4.05109 14.25 4.25V5.75H15.75C15.9489 5.75 16.1397 5.82902 16.2803 5.96967C16.421 6.11032 16.5 6.30109 16.5 6.5C16.5 6.69891 16.421 6.88968 16.2803 7.03033C16.1397 7.17098 15.9489 7.25 15.75 7.25H14.25V8.75C14.25 8.94891 14.171 9.13968 14.0303 9.28033C13.8897 9.42098 13.6989 9.5 13.5 9.5C13.3011 9.5 13.1103 9.42098 12.9697 9.28033C12.829 9.13968 12.75 8.94891 12.75 8.75V7.25H11.25C11.0511 7.25 10.8603 7.17098 10.7197 7.03033C10.579 6.88968 10.5 6.69891 10.5 6.5C10.5 6.30109 10.579 6.11032 10.7197 5.96967C10.8603 5.82902 11.0511 5.75 11.25 5.75H12.75V4.25C12.75 4.05109 12.829 3.86032 12.9697 3.71967C13.1103 3.57902 13.3011 3.5 13.5 3.5Z"
          fill="#5C5F62"
        />
      </g>
      <defs>
        <clipPath id="clip0_22002_123920">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
