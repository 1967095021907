export const NavigationCustomerIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_22002_123845)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 3C9.54037 3 9.08525 3.09053 8.66061 3.26642C8.23597 3.44231 7.85013 3.70012 7.52513 4.02513C7.20012 4.35013 6.94231 4.73597 6.76642 5.16061C6.59053 5.58525 6.5 6.04037 6.5 6.5C6.5 6.95963 6.59053 7.41475 6.76642 7.83939C6.94231 8.26403 7.20012 8.64987 7.52513 8.97487C7.85013 9.29988 8.23597 9.55769 8.66061 9.73358C9.08525 9.90947 9.54037 10 10 10C10.9283 10 11.8185 9.63125 12.4749 8.97487C13.1313 8.3185 13.5 7.42826 13.5 6.5C13.5 5.57174 13.1313 4.6815 12.4749 4.02513C11.8185 3.36875 10.9283 3 10 3ZM8 6.5C8 5.96957 8.21071 5.46086 8.58579 5.08579C8.96086 4.71071 9.46957 4.5 10 4.5C10.5304 4.5 11.0391 4.71071 11.4142 5.08579C11.7893 5.46086 12 5.96957 12 6.5C12 7.03043 11.7893 7.53914 11.4142 7.91421C11.0391 8.28929 10.5304 8.5 10 8.5C9.46957 8.5 8.96086 8.28929 8.58579 7.91421C8.21071 7.53914 8 7.03043 8 6.5Z"
          fill="#5C5F62"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.4841 14.2268C14.9408 13.2487 14.1459 12.4337 13.1816 11.8662C12.2174 11.2988 11.1189 10.9995 10.0001 10.9995C8.88123 10.9995 7.78275 11.2988 6.81849 11.8662C5.85424 12.4337 5.0593 13.2487 4.51607 14.2268L4.07907 15.0128C3.9661 15.2165 3.90825 15.4461 3.91124 15.679C3.91424 15.9119 3.97798 16.14 4.09616 16.3407C4.21434 16.5414 4.38287 16.7078 4.58507 16.8234C4.78728 16.939 5.01616 16.9998 5.24907 16.9998H14.7511C14.984 16.9998 15.2129 16.939 15.4151 16.8234C15.6173 16.7078 15.7858 16.5414 15.904 16.3407C16.0222 16.14 16.0859 15.9119 16.0889 15.679C16.0919 15.4461 16.034 15.2165 15.9211 15.0128L15.4841 14.2268ZM5.82707 14.9548C6.24035 14.2104 6.84522 13.5901 7.57898 13.1583C8.31273 12.7264 9.14865 12.4986 10.0001 12.4986C10.8515 12.4986 11.6874 12.7264 12.4212 13.1583C13.1549 13.5901 13.7598 14.2104 14.1731 14.9548L14.4751 15.4998H5.52507L5.82707 14.9548Z"
          fill="#5C5F62"
        />
      </g>
      <defs>
        <clipPath id="clip0_22002_123845">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
