import {
  IS_BROWSER,
  adminRoutePrefix,
  appPrefixedHost,
  fromShopifyAppPropertyName,
  shopUrlPropertyName,
} from "@smartrr/shared/constants";
import { getParsedQuery } from "@smartrr/shared/utils/frontendQueryUtils";
import { frontEndTabOpen } from "@smartrr/shared/utils/locationUtils";
import omit from "lodash/omit";
import { stringify } from "qs";

import { getShopUrlFromGlobals } from "./getShopUrlFromGlobals";

export function doOpenStandaloneInNewTab(): void {
  if (!IS_BROWSER) {
    return;
  }

  const host = `${appPrefixedHost}${window?.location?.pathname || adminRoutePrefix}`;
  const query = stringify(
    {
      ...omit(getParsedQuery(), [fromShopifyAppPropertyName]),
      [shopUrlPropertyName]: getShopUrlFromGlobals(),
    },
    { addQueryPrefix: true }
  );

  frontEndTabOpen(`https://${host}${query}`);
}
