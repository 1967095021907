export const NavigationTranslationsIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_26956_18765)">
        <g clipPath="url(#clip1_26956_18765)">
          <g clipPath="url(#clip2_26956_18765)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.95504 3.99412C6.90249 3.84933 6.80663 3.72423 6.6805 3.63583C6.55437 3.54743 6.40407 3.5 6.25004 3.5C6.09601 3.5 5.94572 3.54743 5.81958 3.63583C5.69345 3.72423 5.59759 3.84933 5.54504 3.99412L3.54504 9.49412C3.47715 9.6811 3.48631 9.88739 3.57051 10.0676C3.65472 10.2478 3.80706 10.3872 3.99404 10.4551C4.18102 10.523 4.38731 10.5139 4.56753 10.4296C4.74775 10.3454 4.88715 10.1931 4.95504 10.0061L5.14204 9.49212C5.1778 9.49736 5.2139 9.50004 5.25004 9.50012H7.36104L7.54504 10.0061C7.61294 10.1931 7.75233 10.3454 7.93255 10.4296C8.11277 10.5139 8.31906 10.523 8.50604 10.4551C8.69302 10.3872 8.84537 10.2478 8.92957 10.0676C9.01377 9.88739 9.02294 9.6811 8.95504 9.49412L6.95504 3.99412ZM6.25004 6.44412L6.81604 8.00012H5.68404L6.25004 6.44512V6.44412Z"
              fill="#5C5F62"
            />
            <path
              d="M14.5498 7.74985V6.74985C14.5498 6.68354 14.5235 6.61995 14.4766 6.57307C14.4297 6.52619 14.3661 6.49985 14.2998 6.49985H12.8098L13.0298 6.71985C13.1035 6.78851 13.1626 6.87131 13.2036 6.96331C13.2446 7.05531 13.2666 7.15462 13.2684 7.25532C13.2702 7.35603 13.2517 7.45606 13.214 7.54944C13.1762 7.64283 13.1201 7.72767 13.0489 7.79888C12.9776 7.8701 12.8928 7.92625 12.7994 7.96397C12.706 8.00169 12.606 8.02022 12.5053 8.01844C12.4046 8.01666 12.3053 7.99462 12.2133 7.95363C12.1213 7.91264 12.0385 7.85353 11.9698 7.77985L10.4698 6.27985C10.3294 6.13922 10.2505 5.9486 10.2505 5.74985C10.2505 5.5511 10.3294 5.36047 10.4698 5.21985L11.9698 3.71985C12.112 3.58737 12.3 3.51524 12.4944 3.51867C12.6887 3.5221 12.874 3.60081 13.0114 3.73822C13.1489 3.87564 13.2276 4.06102 13.231 4.25532C13.2344 4.44963 13.1623 4.63767 13.0298 4.77985L12.8098 4.99985H14.2998C15.2658 4.99985 16.0498 5.78285 16.0498 6.74985V7.74985C16.0498 7.94876 15.9708 8.13952 15.8302 8.28018C15.6895 8.42083 15.4987 8.49985 15.2998 8.49985C15.1009 8.49985 14.9102 8.42083 14.7695 8.28018C14.6288 8.13952 14.5498 7.94876 14.5498 7.74985Z"
              fill="#5C5F62"
            />
            <path
              d="M5.5 12.25V13.25C5.5 13.388 5.612 13.5 5.75 13.5H7.24L7.02 13.28C6.94631 13.2113 6.88721 13.1285 6.84622 13.0365C6.80523 12.9445 6.78319 12.8452 6.78141 12.7445C6.77963 12.6438 6.79816 12.5438 6.83588 12.4504C6.8736 12.357 6.92974 12.2722 7.00096 12.201C7.07218 12.1297 7.15701 12.0736 7.2504 12.0359C7.34379 11.9982 7.44382 11.9796 7.54452 11.9814C7.64523 11.9832 7.74454 12.0052 7.83654 12.0462C7.92854 12.0872 8.01134 12.1463 8.08 12.22L9.58 13.72C9.72045 13.8606 9.79934 14.0512 9.79934 14.25C9.79934 14.4488 9.72045 14.6394 9.58 14.78L8.08 16.28C7.93782 16.4125 7.74978 16.4846 7.55548 16.4812C7.36118 16.4777 7.17579 16.399 7.03838 16.2616C6.90097 16.1242 6.82225 15.9388 6.81882 15.7445C6.8154 15.5502 6.88752 15.3622 7.02 15.22L7.24 15H5.75C5.28587 15 4.84075 14.8156 4.51256 14.4874C4.18437 14.1592 4 13.7141 4 13.25V12.25C4 12.0511 4.07902 11.8603 4.21967 11.7197C4.36032 11.579 4.55109 11.5 4.75 11.5C4.94891 11.5 5.13968 11.579 5.28033 11.7197C5.42098 11.8603 5.5 12.0511 5.5 12.25Z"
              fill="#5C5F62"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.002 11.5H10.75C10.5511 11.5 10.3603 11.421 10.2197 11.2803C10.079 11.1397 10 10.9489 10 10.75C10 10.5511 10.079 10.3603 10.2197 10.2197C10.3603 10.079 10.5511 10 10.75 10H12.5V9.75C12.5 9.55109 12.579 9.36032 12.7197 9.21967C12.8603 9.07902 13.0511 9 13.25 9C13.4489 9 13.6397 9.07902 13.7803 9.21967C13.921 9.36032 14 9.55109 14 9.75V10H15.75C15.9489 10 16.1397 10.079 16.2803 10.2197C16.421 10.3603 16.5 10.5511 16.5 10.75C16.5 10.9489 16.421 11.1397 16.2803 11.2803C16.1397 11.421 15.9489 11.5 15.75 11.5H15.438C15.416 12.1663 15.2807 12.824 15.038 13.445C14.8655 13.8838 14.6333 14.2966 14.348 14.672C14.641 14.833 14.982 14.972 15.378 15.074C15.5685 15.1258 15.7308 15.2505 15.8299 15.4212C15.929 15.5918 15.9569 15.7947 15.9074 15.9858C15.8579 16.1768 15.7351 16.3407 15.5656 16.4418C15.3962 16.5429 15.1936 16.5732 15.002 16.526C14.367 16.368 13.7641 16.1014 13.22 15.738C12.6759 16.1014 12.073 16.368 11.438 16.526C11.3422 16.552 11.2422 16.5588 11.1438 16.5459C11.0454 16.533 10.9506 16.5007 10.8648 16.4508C10.7789 16.401 10.7038 16.3346 10.6439 16.2556C10.5839 16.1765 10.5402 16.0863 10.5153 15.9903C10.4904 15.8942 10.4848 15.7941 10.4989 15.6959C10.513 15.5976 10.5464 15.5032 10.5973 15.4179C10.6481 15.3327 10.7154 15.2584 10.7952 15.1994C10.8749 15.1404 10.9656 15.0977 11.062 15.074C11.458 14.971 11.799 14.834 12.092 14.672C11.8065 14.2968 11.5744 13.8838 11.402 13.445C11.1594 12.824 11.0241 12.1663 11.002 11.5ZM13.937 11.5H12.503C12.5246 11.9777 12.6243 12.4485 12.798 12.894C12.899 13.151 13.035 13.418 13.22 13.676C13.405 13.418 13.54 13.151 13.642 12.894C13.8157 12.4485 13.9153 11.9776 13.937 11.5Z"
              fill="#5C5F62"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_26956_18765">
          <rect width="20" height="20" fill="white" />
        </clipPath>
        <clipPath id="clip1_26956_18765">
          <rect width="20" height="20" fill="white" />
        </clipPath>
        <clipPath id="clip2_26956_18765">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
