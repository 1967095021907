export const NavigationHomeIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_22002_127091)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.344 3.69212C8.55474 3.46302 8.81072 3.28013 9.09577 3.15503C9.38081 3.02992 9.68871 2.96533 10 2.96533C10.3113 2.96533 10.6192 3.02992 10.9042 3.15503C11.1893 3.28013 11.4453 3.46302 11.656 3.69212L15.51 7.88212C16.1465 8.5741 16.4998 9.47993 16.5 10.4201V13.7501C16.5 14.4795 16.2103 15.1789 15.6945 15.6947C15.1788 16.2104 14.4793 16.5001 13.75 16.5001H12C11.6022 16.5001 11.2206 16.3421 10.9393 16.0608C10.658 15.7795 10.5 15.3979 10.5 15.0001V13.0001H9.5V15.0001C9.5 15.3979 9.34196 15.7795 9.06066 16.0608C8.77936 16.3421 8.39782 16.5001 8 16.5001H6.25C5.52065 16.5001 4.82118 16.2104 4.30546 15.6947C3.78973 15.1789 3.5 14.4795 3.5 13.7501V10.4201C3.5 9.48012 3.853 8.57412 4.49 7.88112L8.344 3.69212ZM10.552 4.70812C10.4818 4.63175 10.3964 4.57079 10.3014 4.52909C10.2064 4.48739 10.1038 4.46586 10 4.46586C9.89624 4.46586 9.7936 4.48739 9.69859 4.52909C9.60357 4.57079 9.51825 4.63175 9.448 4.70812L5.594 8.89712C5.21206 9.31236 5.00005 9.85594 5 10.4201V13.7501C5 14.4401 5.56 15.0001 6.25 15.0001H8V13.0001C8 12.6023 8.15804 12.2208 8.43934 11.9395C8.72064 11.6582 9.10218 11.5001 9.5 11.5001H10.5C10.8978 11.5001 11.2794 11.6582 11.5607 11.9395C11.842 12.2208 12 12.6023 12 13.0001V15.0001H13.75C14.44 15.0001 15 14.4401 15 13.7501V10.4201C14.9999 9.85594 14.7879 9.31236 14.406 8.89712L10.552 4.70812Z"
          fill="#5C5F62"
        />
      </g>
      <defs>
        <clipPath id="clip0_22002_127091">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
