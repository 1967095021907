import { mapValues, pickBy } from "lodash";

import { ISODateString } from "./ISODateString";
import { IPurchasableCollection } from "./PurchasableCollection";
import { IPurchasableVariant } from "./PurchasableVariant";
import { ICreateEntity, IShopifyMirroredEntityFields } from "./shared/SharedEntityFields";
import { shopifyGidToNumber } from "../utils/ensureShopifyGid";
import { PaginationResult } from "../utils/paginatedQuery";
import { sortByDate } from "../utils/sortBy";

export interface IPurchasablesReducerState {
  isLoading: boolean;
  isLoadingAdditionaly: boolean;
  isStreaming: boolean;
  purchasables: IPurchasable[];
  pageNumber: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
}

export interface IPurchasable extends IShopifyMirroredEntityFields {
  purchasableName: string;
  purchasableImages?: string[];
  purchasableSmallImages?: string[];
  shopifyProductStorefrontId?: string;
  description: string;
  isActiveInShopify: boolean;
  isDraftOrArchived?: boolean;
  smartrrTags?: string[];

  // relationships
  vnts?: IPurchasableVariant[];
  cols?: IPurchasableCollection[];
}

export type IPurchasableCreate = ICreateEntity<IPurchasable>;

export type IPurchasablePaginatedResponse = PaginationResult<IPurchasable>;

export interface IShopifyProductIdToShopifyVariantIdMap {
  [productShopifyId: string]: number[];
}

export interface IShopifyProductIdToShopifyVariantMap {
  [productShopifyId: string]: IShopifySmartrrVariant[];
}

export interface IShopifySmartrrVariant {
  shopifyId: number;
  smartrrId: string;
}

export function purchasablesToShopifyProductIdToShopifyVariantIdMap(
  purchasables: IPurchasable[]
): IShopifyProductIdToShopifyVariantIdMap {
  return getCleanedAndSortedPurchasables(purchasables)
    .filter(purch => purch.isActiveInShopify)
    .reduce((accum: IShopifyProductIdToShopifyVariantIdMap, curr: IPurchasable) => {
      if (curr.shopifyId) {
        accum[`${shopifyGidToNumber(curr.shopifyId)}`] = (curr.vnts || [])
          .filter(vnt => vnt.isActiveInShopify)
          .map(vnt => vnt.shopifyId && shopifyGidToNumber(vnt.shopifyId))
          .filter((shopifyId): shopifyId is number => !!shopifyId);
      }

      return accum;
    }, {});
}

export function purchasablesToShopifyProductIdToShopifyVariantMap(
  purchasables: IPurchasable[]
): IShopifyProductIdToShopifyVariantMap {
  return getCleanedAndSortedPurchasables(purchasables)
    .filter(purch => purch.isActiveInShopify)
    .reduce((accum: IShopifyProductIdToShopifyVariantMap, curr: IPurchasable) => {
      if (curr.shopifyId) {
        const variantObjects = (curr.vnts || [])
          .filter(vnt => vnt.isActiveInShopify)
          .map(vnt => {
            const shopifyId = vnt.shopifyId && shopifyGidToNumber(vnt.shopifyId);
            const smartrrId = vnt.id;
            return { shopifyId, smartrrId };
          })
          .filter((variant): variant is IShopifySmartrrVariant => !!variant.shopifyId);

        accum[`${shopifyGidToNumber(curr.shopifyId)}`] = variantObjects;
      }

      return accum;
    }, {});
}

export function getFilteredShopifyProductIdToShopifyVariantIdMap(
  purchasables: IPurchasable[],
  shopifyProductIds: (string | number)[],
  shopifyProductVariantIds: (string | number)[]
): IShopifyProductIdToShopifyVariantIdMap {
  const numericShopifyProductIds = (shopifyProductIds || []).map(shopifyGidToNumber);
  const numericShopifyVariantIds = (shopifyProductVariantIds || []).map(shopifyGidToNumber);

  const fullPurchasableToVariantShopifyIdMap = purchasablesToShopifyProductIdToShopifyVariantIdMap(
    getCleanedAndSortedPurchasables(purchasables)
  );

  const shopifyProductIdsSet = new Set(numericShopifyProductIds);
  const shopifyProductVariantIdsSet = new Set(numericShopifyVariantIds);

  const withProductIdsFiltered = pickBy(
    fullPurchasableToVariantShopifyIdMap,
    (variantShopifyIds, productShopifyId) => {
      const productIdMatched = shopifyProductIdsSet.has(+productShopifyId);
      const variantIdMatched = variantShopifyIds.some(
        shopifyProductVariantIdsSet.has.bind(shopifyProductVariantIdsSet)
      );
      return productIdMatched || variantIdMatched;
    }
  );

  return mapValues(withProductIdsFiltered, (variantShopifyIds, productShopifyId) => {
    const productIdMatched = shopifyProductIdsSet.has(+productShopifyId);

    if (productIdMatched) {
      return variantShopifyIds;
    }

    return variantShopifyIds.filter(shopifyProductVariantIdsSet.has.bind(shopifyProductVariantIdsSet));
  });
}

export function getFilteredShopifyProductIdToShopifyVariantMap(
  purchasables: IPurchasable[],
  shopifyProductIds: (string | number)[],
  shopifyProductVariantIds: (string | number)[]
): IShopifyProductIdToShopifyVariantMap {
  const numericShopifyProductIds = (shopifyProductIds || []).map(shopifyGidToNumber);
  const numericShopifyVariantIds = (shopifyProductVariantIds || []).map(shopifyGidToNumber);

  const fullPurchasableToVariantShopifyIdMap = purchasablesToShopifyProductIdToShopifyVariantMap(
    getCleanedAndSortedPurchasables(purchasables)
  );

  const shopifyProductIdsSet = new Set(numericShopifyProductIds);
  const shopifyProductVariantIdsSet = new Set(numericShopifyVariantIds);

  const withProductIdsFiltered = pickBy(
    fullPurchasableToVariantShopifyIdMap,
    (variantShopifyIds, productShopifyId) => {
      const productIdMatched = shopifyProductIdsSet.has(+productShopifyId);
      const variantIdMatched = variantShopifyIds.some(variant => {
        const isInSet = shopifyProductVariantIdsSet.has.bind(shopifyProductVariantIdsSet);
        return isInSet(variant.shopifyId);
      });
      return productIdMatched || variantIdMatched;
    }
  );

  return mapValues(withProductIdsFiltered, (variantShopifyIds, productShopifyId) => {
    const productIdMatched = shopifyProductIdsSet.has(+productShopifyId);

    if (productIdMatched) {
      return variantShopifyIds;
    }

    return variantShopifyIds.filter(variant => {
      const isInSet = shopifyProductVariantIdsSet.has.bind(shopifyProductVariantIdsSet);
      return isInSet(variant.shopifyId);
    });
  });
}

export function getCleanedAndSortedPurchasables(purchasables: IPurchasable[]): IPurchasable[] {
  return sortAllPurchasableVariantsByDate(purchasables)
    .filter(purchasable => {
      if (purchasable.purchasableName.endsWith("Auto renew")) {
        return false;
      }
      return true;
    })
    .sort((a, b) => {
      if (a.isActiveInShopify && !b.isActiveInShopify) {
        return -1;
      }
      return a.purchasableName < b.purchasableName ? -1 : 1;
    })
    .map(purchasables => ({
      ...purchasables,
      vnts: [...(purchasables.vnts || [])].sort((a, b) => {
        if (a.isActiveInShopify && !b.isActiveInShopify) {
          return -1;
        }
        return (a.purchasableVariantName || "") < (b.purchasableVariantName || "") ? -1 : 1;
      }),
    }));
}

function sortAllPurchasableVariantsByDate(purchasables: IPurchasable[]): IPurchasable[] {
  return purchasables.map(sortPurchasableVariantsByDate);
}

function sortPurchasableVariantsByDate(purchasable: IPurchasable) {
  return {
    ...purchasable,
    vnts: sortByDate([...(purchasable.vnts || [])], variant => ISODateString.fromString(variant.createdDate)),
  };
}
